import React from 'react';
import { graphql } from 'gatsby';

import { useLocale } from '@app/hooks';
import { Layout, Page as P, Seo } from '@app/components';

type GalleryPropTypes = {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        path: string;
        parentMenu: string;
        date: string;
        head?: boolean;
      };
      html: string;
    };
  };
};

const Gallery: React.FC<GalleryPropTypes> = ({ data }) => {
  const SCOPE_OPTIONS = {
    scope: 'templates.page',
  };
  const { t } = useLocale();

  const { frontmatter, html } = data.markdownRemark;

  console.log(frontmatter.title);
  return (
    <Layout>
      <Seo title={frontmatter.title} />
      {frontmatter.head ?? (
        <P.Head
          title={frontmatter.title}
          date={frontmatter.date}
          breadcrumb={[
            { path: '', name: t('labels.home', SCOPE_OPTIONS) },
            {
              path: `/${frontmatter.parent}`,
              name: frontmatter.parent && frontmatter.parent.replace('-', ' '),
            },
            { path: `/${frontmatter.path}`, name: frontmatter.title },
          ]}
        />
      )}

      <div className="post-content">
        <div className="container mx-auto px-4">
          <div className="py-16" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
    </Layout>
  );
};

export default Gallery;

export const query = graphql`
  query ($language: String!, $slug: String!) {
    markdownRemark(
      frontmatter: { path: { eq: $slug }, language: { eq: $language }, type: { eq: "gallery" } }
    ) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY", locale: $language)
        path
        parentMenu
        head
      }
      html
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
